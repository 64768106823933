.save-question-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  height: 92vh;
}

.save-question {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  padding: 20px;
  background-color: var(--light-blue);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  gap: 1rem;
}

.form-options {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label-class {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.3rem;
  color: var(--black);
}

.label-class-2 {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.3rem;
  color: var(--black);
}

.file-input {
  display: flex;
  align-items: center;
  /* border: 1px solid var(--black); */
  border-radius: 10px;
  padding-top: 0.3rem;
  gap: 5px;
  background-color: white;
}

.input-file,
.input-text,
.select-option {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
  font-size: 1.3rem;
  /* border: 0.5px solid var(--black); */
}

.input-file,
.input-text,
.select-option:focus {
  outline: none;
}

.btn-save-question {
  padding: 10px;
  background-color: var(--blue);
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 10px;
}

.btn-save-question:hover {
  background-color: var(--orange);
}

.preview {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  gap: 1rem;
}

.preview img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

i {
  color: lightblue;
  cursor: pointer;
  padding: 5px;
}

#file-question {
  border: none;
  display: none;
}

#file-answer {
  border: none;
  display: none;
}

.answer-lbl {
  width: 100%;
  text-align: center;
  padding: 0.2rem;
  justify-self: start;
  font-size: 1.3rem;
}

.question-lbl {
  width: 100%;
  text-align: center;
  padding: 0.2rem;
  justify-self: start;
  font-size: 1.3rem;
}

.react-crop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
