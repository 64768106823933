.all-questions-main {
  background-color: white;
}

.all-questions-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.numberSearch {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid lightblue;
  cursor: pointer;
}

.numberSearch:hover {
  border: 1px solid black;
}

.search {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 30%;
}

.search > input {
  width: 100%;
  border-radius: 1.5rem;
  border: 1px solid lightblue;
  padding: 1rem;
}

.search > input:hover {
  border: 1px solid rgb(27, 48, 133);
}

.search > input:focus {
  outline: none;
}

.question-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 2rem;
  gap: 2rem;
}

.question {
  padding: 1rem;
  border: 1px solid rgba(67, 99, 213, 0.532);
  background-color: var(--light-blue);
  border-radius: 1rem;
  display: flex;
  width: 22%;
}

.question-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-img {
  cursor: pointer;
  border-radius: 1rem;
  width: 100%;
}

.question-details {
  display: flex;
  width: 100%;
  justify-content: start;
}

.question-change {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.question-change-info-header {
  width: 30%;
  display: flex;
  gap: 1rem;
}

.question-change-info {
  display: flex;
  gap: 1rem;
}

.select-konu {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid lightblue;
  cursor: pointer;
}

.select-konu:hover {
  border: 1px solid black;
}

.select-zorluk {
  display: flex;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid lightblue;
  cursor: pointer;
}

.select-zorluk:hover {
  border: 1px solid black;
}

.update-button {
  display: flex;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #00ff0067;
  background-color: white;
}

.update-button:hover {
  border: 1px solid lightblue;
  background-color: #00ff00;
}

.delete-button {
  display: flex;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #fc280266;
  background-color: white;
}

.delete-button:hover {
  border: 1px solid lightblue;
  background-color: #fc2802;
}

.question-button {
  display: flex;
  gap: 1rem;
}

.pagi-line {
  height: 1px;
  width: 75%;
  background-color: 1px solid blue;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}

.pagination > button {
  color: white;
  background-color: var(--blue);
  border-radius: 0.5rem;
}

.question-container-modal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  flex-direction: column;
  gap: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-form-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-img-modal {
  width: 35rem;
}
