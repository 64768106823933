:root {
  --black: black;
  --white: #eeeeee;
  --blue: #373a6d;
  --yellow: #fff9d0;
  --light-blue: #cad4ef;
  --orange: #ff8246;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-blue);
  padding: 5px;
}

ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style-type: none;
  overflow: hidden;
}

li {
  float: left;
}

li a {
  display: block;
  color: var(--black);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.3rem;
}

li a:hover,
a:active {
  background-color: var(--blue);
  color: var(--white);
  text-decoration: none;
  border-radius: 5px;
}

.line {
  width: 0.5px;
  height: 25px;
  background-color: black;
}

.user {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
}

.btn-logout {
  margin: 0rem 2rem;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: red;
  font-size: 1.3rem;
  color: rgb(255, 255, 255);
}

/* .btn-logout:hover {
} */
