.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 30%;
    align-items: center;
  }
  
  .name-input {
    border: 2px solid rgb(0, 191, 255);
    width: 100%;
    border-radius: 5px;
    height: 40px;
    transition: background-color 0.3s ease;
  }
  
  .name-input:hover {
    background-color: rgb(171, 245, 250);
    border: 1px solid rgb(0, 0, 0);
  }

  .password-input {
    border: 2px solid rgb(0, 191, 255);
    width: 100%;
    border-radius: 5px;
    height: 40px;
    transition: background-color 0.3s ease;
  }
  
  .password-input:hover {
    background-color: rgb(171, 245, 250);
    border: 1px solid rgb(0, 0, 0);
  }
  
  .btn-outline-primary {
    width: 50%;
  }
